import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Home from './Home';
import Projects from './Projects';
import Art from './Art';  // Importing the Art component correctly

ReactDOM.render(
    <Router>
        <div>
            <div className="menu-bar">
                <nav>
                    <div className="menu">
                        <NavLink className="navLinkText hoverGeneral" to="/">
                            HOME
                        </NavLink>
                        <NavLink className="navLinkText hoverGeneral" to="/projects">
                            PROJECTS
                        </NavLink>
                        <NavLink className="navLinkText hoverGeneral" to="/art">
                            ART
                        </NavLink> {/* Correct path for ART */}
                    </div>
                </nav>
            </div>

            <Routes>
                <Route path="/projects" element={<Projects />} />
                <Route path="/art" element={<Art />} /> {/* Correct route for Art */}
                <Route path="/" element={<Home />} />
            </Routes>
        </div>
    </Router>,
    document.getElementById('root')
);
