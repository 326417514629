import React, { Component } from "react";

const images = [
    `${process.env.PUBLIC_URL}/INNER-CHILD-2.JPG`,
    `${process.env.PUBLIC_URL}/INNER-CHILD-3.JPG`,
    `${process.env.PUBLIC_URL}/INNER-CHILD-4.JPG`,
    `${process.env.PUBLIC_URL}/INNER-CHILD-1.JPG`,
    `${process.env.PUBLIC_URL}/INNER-CHILD-7.JPG`,
    `${process.env.PUBLIC_URL}/INNER-CHILD-9.JPG`,
];

class Art extends Component {
    state = {
        project: "Inner Child",
        content: images,
    };

    changeProject = (projectName) => {
        if (projectName === "Inner Child") {
            this.setState({ content: images, project: projectName });
        } else {
            // You can add other projects here similarly.
            this.setState({ content: [], project: projectName });
        }
    };

    render() {
        const { project, content } = this.state;

        return (
            <div className="mainDiv">
                <div className="mainMarginDiv">
                    <p className="project-text">ART PORTFOLIO</p>
                    <br />
                    <br />
                    <div className="projectButtonDiv">
                        {["Inner Child", "A Series of Writings", "Identity Layers", "Pixel Dreams"].map((project) => (
                            <button
                                key={project}
                                style={{
                                    background: project === this.state.project ? "#000" : "transparent",
                                    color: project === this.state.project ? "#FFFFFF" : "#000",
                                }}
                                onClick={() => this.changeProject(project)}
                            >
                                {project}
                            </button>
                        ))}
                    </div>

                    <br />
                    <br />

                    <div className="mainPictures">
                        {content.length > 0 ? (
                            content.map((picture, index) => (
                                <div className="imageDiv" key={index}>
                                    <img src={picture} alt={project} className="photo" />
                                </div>
                            ))
                        ) : (
                            <p>No images available for this project.</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Art;