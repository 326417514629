import React from "react";

class Project extends React.Component {
    state = {
        popUpActive: false,
        firstDescription: false,
    };

    render() {
        return (
            <div className="mainDiv">
                {/* Popup for project details */}
                <div
                    className="projectPopUps"
                    style={{
                        opacity: this.state.popUpActive ? 1 : 0,
                        visibility: this.state.popUpActive ? "visible" : "hidden",
                    }}
                >
                    <div className="popupWhiteSquare">
                        <div className="xDiv">
              <span
                  onClick={() =>
                      this.setState({
                          popUpActive: false,
                          firstDescription: false,
                      })
                  }
                  className="hoverGeneral xText"
              >
                X
              </span>
                        </div>

                        {/* ArtsCrew Project Description */}
                        <div
                            className="popUpContent"
                            style={{
                                display: this.state.firstDescription ? "block" : "none",
                                opacity: this.state.firstDescription ? 1 : 0,
                                visibility: this.state.firstDescription ? "visible" : "hidden",
                            }}
                        >
                            <h4>ArtsCrew App</h4>
                            <i>
                                The ArtsCrew app was designed to streamline collaboration within the creative and
                                performing arts community at Brown University and beyond.
                                By bringing together a comprehensive platform for communication, resource sharing,
                                and event management, the app enables artists, educators, and students to engage in
                                projects, track opportunities, and collaborate across various creative fields.
                                <br/><br/>
                                The app also integrates tools for organizing schedules, accessing key resources, and
                                keeping track of ongoing projects. By centralizing these functionalities, ArtsCrew helps
                                reduce the friction often associated with creative collaboration, allowing users to
                                focus more on their craft and less on administrative hurdles. It also includes access
                                to exclusive perks like
                                workshops, studio spaces, and software tools for enhancing artistic output.
                            </i>
                            <br/><br/>
                            <a
                                href="https://www.figma.com/proto/to2iDqkPMzWQ8XUUCmxvl9/ArtsCrew-Wireframe-%26-Prototype?node-id=641-6936&node-type=canvas&t=ihCJ3BXUN0PsnY8P-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=220%3A3934&show-proto-sidebar=1"
                                target="_blank"
                                className="hoverGeneral"
                                rel="noreferrer"
                            >
                                View ArtsCrew Prototype
                            </a>
                        </div>
                    </div>
                </div>

                {/* Project thumbnails grid */}
                <div className="mainMarginDiv">
                    <div className="projectGrid">
                        <div className="projectThumbnail"
                             onClick={() => this.setState({popUpActive: true, firstDescription: true})}>
                            <img
                                src={`${process.env.PUBLIC_URL}/ArtsCrew.png`} // ArtsCrew Image
                                className="projectImg hoverGeneral"
                                alt="ArtsCrew Prototype"
                            />
                            <div className="overlay">
                                <span className="overlayText">ArtsCrew App</span>
                            </div>
                        </div>

                        {/* Placeholder images for other projects */}
                        <div className="projectThumbnail">
                            <img
                                src="#"
                                className="projectImg hoverGeneral"
                                alt="Project 2"
                            />
                            <div className="overlay">
                                <span className="overlayText">Project 2</span>
                            </div>
                        </div>
                    </div>

                    {/* Second row for additional projects */}
                    <div className="projectGrid">
                        <div className="projectThumbnail">
                            <img
                                src="#"
                                className="projectImg hoverGeneral"
                                alt="Project 3"
                            />
                            <div className="overlay">
                                <span className="overlayText">Project 3</span>
                            </div>
                        </div>

                        <div className="projectThumbnail">
                            <img
                                src="#"
                                className="projectImg hoverGeneral"
                                alt="Project 4"
                            />
                            <div className="overlay">
                                <span className="overlayText">Project 4</span>
                            </div>
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}

export default Project;