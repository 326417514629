import React, { useCallback } from "react";
import './index.css';
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

const Home = () => {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);

    return (
        <div className="mainDiv">
            <Particles
                className="particles"
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {
                        color: {
                            value: "white",
                        },
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "grab", // Changed from "repulse" to "grab"
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            grab: {
                                distance: 200, // The distance at which the grab effect occurs
                                line_linked: {
                                    opacity: 1,
                                },
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: ["#000000", "#555555"], // Slight gradient effect from black to dark gray
                        },
                        links: {
                            color: "#000000",
                            distance: 200,
                            enable: true,
                            opacity: 0.4, // Slightly more transparent links
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 1.5, // Slower speed for a smoother effect
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1000,
                            },
                            value: 120, // Increased particle count
                        },
                        opacity: {
                            value: 0.6, // Slightly higher opacity for the particles
                        },
                        shape: {
                            type: ["circle", "triangle", "polygon"], // Added triangles and polygons
                            polygon: {
                                nb_sides: 5, // Number of sides for polygons
                            },
                        },
                        size: {
                            value: { min: 1, max: 3 }, // Small size variation for more dynamic particles
                        },
                    },
                    detectRetina: true,
                }}
                style={{
                    position: "fixed",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            />
            <div className="content" style={{position: "relative", zIndex: 1}}>
                <img
                    src={`${process.env.PUBLIC_URL}/ProfilePhoto.png`}
                    alt="Profile"
                    className="profile-photo"
                />
                <h1 className="name">Ethan McDowell</h1>
                <p>
                    Welcome to my portfolio, where I bring together my passion for coding and design. I’ve always been
                    fascinated by how technology shapes the world, and through my projects, I strive to create solutions
                    that are both functional and visually compelling.
                </p>
                <p>
                    As a Computer Science and Visual Art double-concentrator at Brown University, I combine technical
                    problem-solving with creative expression. From UI/UX design to software engineering, my work
                    reflects my drive to bridge the gap between creativity and logic.
                </p>
                <p>
                    Outside of coding, I’m an avid artist, blending digital tools with traditional techniques. I love
                    exploring how technology can push the boundaries of art, and my personal projects often reflect this
                    intersection.
                </p>
                <p>
                    Feel free to browse my work, whether it’s code, design, or a fusion of both. I’m always looking to
                    collaborate on projects that challenge my skills and expand my horizons.
                </p>
            </div>
        </div>
    );
};

export default Home;